import OrderArticle, { getTotalPrice } from "../../models/order/OrderArticle";
import Campaign from "../../models/campaigns/Campaign";

export default function calculateXthItemYPercentageOffCampaign(campaign: Campaign, orderArticles: OrderArticle[]) {
  let discountAmount = 0;

  if (campaign.Xs?.length === 2) {
    orderArticles = orderArticles.sort((a, b) => getTotalPrice(a, 1) - getTotalPrice(b, 1));
    const totalCount = orderArticles.reduce((sum, orderArticle) => {
      return sum + orderArticle.count;
    }, 0);
    const numberOfDiscountedItems = Math.floor(totalCount / campaign.Xs[0]);

    let numberOfItemsDiscounted = 0;
    orderArticles.some((orderArticle) => {
      if (numberOfItemsDiscounted + orderArticle.count <= numberOfDiscountedItems) {
        discountAmount += (getTotalPrice(orderArticle) * (campaign.Xs?.[1] ?? 0)) / 100;
        numberOfItemsDiscounted += orderArticle.count;
        return false;
      } else {
        const count = numberOfDiscountedItems - numberOfItemsDiscounted;
        discountAmount += (getTotalPrice(orderArticle, count) * (campaign.Xs?.[1] ?? 0)) / 100;
        numberOfItemsDiscounted += count;
        return true;
      }
    });
  }
  return discountAmount;
}
